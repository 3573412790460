import Axios from '@/services/axios-instance/axios.service';
const API_URL = process.env.NODE_ENV !== 'development' ? `${window.location.origin}` : 'http://localhost:5000';

class StripeService {
  async sendFormData(form) {
    const { data } = await Axios.post(`${API_URL}/pay`, form);
    return data;
  }
  async getCustomerDetails(customerId) {
    const { data } = await Axios.get(`${API_URL}/customer/${customerId}`);
    return data;
  }
}

export default new StripeService();
