<template>
  <div class="packages-container">
    <!--Start Info Modal-->
    <div class="info-modal" v-if="infoModal.isOpen">
      <div class="info-modal-container">
        <img alt="Close" class="close-icon" src="@/assets/close-icon.svg" @click="closeInfoModal()" />
        <div class="info-modal-container-left">
          <img v-if="infoModal.isAlert" alt="Info" class="info-icon" src="@/assets/alert-icon.svg" />
          <img v-else alt="Info" class="info-icon" src="@/assets/info-icon.svg" />
        </div>
        <div class="info-modal-container-right">
          <p class="mt-3">
            <b>{{ infoModal.title }}</b>
          </p>
          <p class="mt-3">{{ infoModal.text }}</p>
        </div>
      </div>
      <div class="info-modal-bg" @click="closeInfoModal()"></div>
    </div>
    <!--End Info Modal-->
    <div class="package-table">
      <b-row>
        <b-col>
          <div class="flex-center text-center">
            <h2>Click Where You Live Now</h2>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <template v-for="(country, index) of countryList" :key="country.code">
          <b-col cols="6" lg="4">
            <div
              @click="handleSelectCountry(index)"
              class="country-item"
              :class="country.isSelected ? 'country-item__selected' : ''"
            >
              <img :src="country.image" class="country-item__image" />
              <div class="mt-2 country-item__name">{{ country.name }}</div>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
    <table class="mb-3 package-table">
      <thead>
        <tr class="header">
          <th style="min-width: 33%">Fees</th>
          <th>Amount</th>
          <th>Payable When?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="include-text">A - Initial Deposit</td>
          <td>{{ currencySymbol }}{{ countrySelected.depositPrice }}</td>
          <td class="include-text">To Start</td>
        </tr>
        <tr>
          <td class="include-text">B - Application Fee</td>
          <td>{{ currencySymbol }}{{ countrySelected.applicationPrice }}</td>
          <td class="include-text">When HMRC Get Your Application from Us</td>
        </tr>
        <tr>
          <td class="hide-border" colspan="3"></td>
        </tr>
        <tr class="total-cost-row">
          <td class="include-text">
            <b>Cost to Apply</b>
            <br />
            <template v-if="tax">(Incl EU VAT)</template>
          </td>
          <td>
            <b>{{ currencySymbol }}{{ countrySelected.depositPrice + countrySelected.applicationPrice }}</b>
          </td>
          <td class="include-text">
            <b>100% Refunded if HMRC REJECT Your Application</b>
          </td>
        </tr>
        <tr>
          <td class="hide-border" colspan="3"></td>
        </tr>
        <tr>
          <td class="include-text">C - Success Fee</td>
          <td>{{ currencySymbol }}{{ countrySelected.successPrice }}</td>
          <td class="include-text">When HMRC Approve Your Application</td>
        </tr>
        <tr class="total-price-row">
          <td class="include-text">
            <b>Total Cost if Successful</b>
            <br />
            <template v-if="tax">(Incl EU VAT)</template>
            <!-- <template v-else>
              (Excl EU VAT)
              <img
                alt="Info"
                class="info-icon"
                src="@/assets/alert-icon.svg"
                @click="
                  openInfoModal(
                    '',
                    'If you live in the EU but choose the cheaper `excl VAT` non-EU option, we will NOT be able to proceed with your application.',
                    true
                  )
                "
            /></template> -->
          </td>
          <td>
            <b>{{ currencySymbol }}{{ countrySelected.totalPrice }}</b>
          </td>
          <td class="include-text">
            <b>Your Total Cost if HMRC APPROVE Your Application to ‘Buy National Insurance Years’ </b>
          </td>
        </tr>
        <tr>
          <td class="hide-border" colspan="3">
            <button class="package-btn" @click="handleClickToBye()">BUY</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="footer-content">
      <div class="flex-center text-center mt-3 mb-3">
        <h2>Unique 100% Money-Back Guarantee</h2>
      </div>
      <div class="footer-content-text">
        <p>
          <b>Our service is 100% FREE if you get a ‘rejection’ letter from HMRC within 2 years</b>
          of buying our service. A ‘rejection’ means you are not eligible to pay HMRC for missing National Insurance
          years to meet their 10 year minimum - required to get any UK state pension.
        </p>
        <ul>
          <li>
            <a
              href="https://assets.website-files.com/646b3f12df737f5a46034de5/6538e1b737ed55e498f2c1db_positive.pdf"
              target="_blank"
              >Sample POSITIVE Letter</a
            >
            <b> of Approval / Assessment</b>
          </li>
          <li>
            <a
              href="https://assets.website-files.com/646b3f12df737f5a46034de5/6538e2265d1392f5c6550ab2_rejection.pdf"
              target="_blank"
              >See Rejection Letter</a
            >
          </li>
        </ul>
        <p><b>Obviously…. This guarantee does not apply if:</b></p>
        <ul>
          <li>You choose NOT to pay HMRC for whatever reason - even though you got a ‘positive’ letter.</li>
          <li>You were declined on our “Am I Eligible?” tool but used our service anyway.</li>
        </ul>
      </div>
      <div class="flex-center">
        <table class="mt-3 mb-3">
          <thead>
            <tr>
              <td colspan="2">
                <h3><b>What's Included in Our Service?</b></h3>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="include-text">
                <b>The initial deposit is refundable</b> up until we send you official documents for your approval.
              </td>
              <td class="include-text"><b>We offer an easy application process</b> with phone & email support.</td>
            </tr>
            <tr>
              <td class="include-text">
                <b>If you approve these documents</b> in order for us to submit them to HMRC, you’re committing to our
                full service and we then submit your application to HMRC and send you proof via Royal Mail.
              </td>
              <td class="include-text">
                <b>We provide progress updates</b> on where your application is in the very long HMRC queue along with
                ongoing support if you have questions at any time.
              </td>
            </tr>
            <tr>
              <td class="include-text">
                <b>We also chase HMRC</b> if there are any non-standard delays or issues or with your application.
              </td>
              <td class="include-text">
                <b>We offer help and guidance</b> when HMRC query your application because your submitted information
                differs from their records.
              </td>
            </tr>
            <tr>
              <td colspan="2" class="include-text">
                <b>Fast Reply from HMRC:</b> You’ll get your HMRC letter of approval/assessment from us within 1 week at
                latest. If you use other agents outside the UK or do this process yourself, you will likely need to wait
                6+ weeks (within EU) to receive your HMRC assessment as they are posted 2nd class via Rotterdam. Given
                there is an approaching deadline for this opportunity, you may only have 8 weeks to pay HMRC so if there
                are any postal delays, you could lose the opportunity.
              </td>
            </tr>
            <tr>
              <td colspan="2" class="include-text">
                <b>Guidance on HMRC Reply:</b> 34% of people entitled to increase their UK pension very cheaply via this
                process choose not to because they’re unclear / unsure of the final very valuable steps. We explain and
                guide you clearly through this important process.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex-center text-center mt-3 mb-3">
        <h2>
          <span>Join almost 5,000 other former UK-based workers</span> who trust
          <br />
          XtraPension to Claim & Maximise their UK State Pension
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
import './HomeCountryList.scss';
/* eslint-disable */
export default {
  name: 'HomeCountryList',
  props: {
    onSelect: { type: Function, required: true }
    // countrySelected: { type: String, required: true }
  },
  computed: {
    tax() {
      return this.countryList.find((item) => item.isSelected).isTax;
    },
    countrySelected() {
      return this.countryList.find((item) => item.isSelected);
    },
    currencySymbol() {
      let currencySymbol = 'US $';
      if (this.countrySelected.name === 'Ireland/Europe') {
        currencySymbol = '€';
      } else if (this.countrySelected.name === 'Canada') {
        currencySymbol = 'CA $';
      } else if (this.countrySelected.name === 'Australia') {
        currencySymbol = 'AU $';
      } else if (this.countrySelected.name === 'New Zealand') {
        currencySymbol = 'NZ $';
      }
      return currencySymbol;
    }
  },
  data() {
    return {
      infoModal: {
        isOpen: false,
        isAlert: false,
        text: '',
        title: ''
      },
      countryList: [
        {
          name: 'Ireland/Europe',
          isSelected: true,
          isTax: true,
          image: require('@/assets/country/Europe.png'),
          depositPrice: 100,
          applicationPrice: 500,
          successPrice: 400,
          totalPrice: 1000
        },
        {
          name: 'Canada',
          isSelected: false,
          isTax: false,
          image: require('@/assets/country/Canada.png'),
          depositPrice: 100,
          applicationPrice: 800,
          successPrice: 600,
          totalPrice: 1500
        },
        {
          name: 'Australia',
          isSelected: false,
          isTax: false,
          image: require('@/assets/country/Australia.png'),
          depositPrice: 150,
          applicationPrice: 850,
          successPrice: 500,
          totalPrice: 1500
        },
        {
          name: 'Rest of World',
          isSelected: false,
          isTax: false,
          image: require('@/assets/country/ROW.png'),
          depositPrice: 100,
          applicationPrice: 500,
          successPrice: 400,
          totalPrice: 1000
        },
        {
          name: 'USA',
          isSelected: false,
          isTax: false,
          image: require('@/assets/country/USA.png'),
          depositPrice: 100,
          applicationPrice: 500,
          successPrice: 400,
          totalPrice: 1000
        },
        {
          name: 'New Zealand',
          isSelected: false,
          isTax: false,
          image: require('@/assets/country/NewZealand.png'),
          depositPrice: 150,
          applicationPrice: 850,
          successPrice: 500,
          totalPrice: 1500
        }
      ]
    };
  },
  mounted() {
    window.addEventListener('keyup', (ev) => {
      if (ev.keyCode === 27 && this.infoModal.isOpen) {
        this.closeInfoModal();
      }
    });
  },
  unmounted() {
    window.removeEventListener('keyup', this.closeInfoModal());
  },
  methods: {
    handleClickToBye() {
      this.onSelect(this.countrySelected);
    },
    handleSelectCountry(index) {
      this.countryList.forEach((country, ind) => {
        country.isSelected = index === ind ? true : false;
      });
    },
    openInfoModal(title, text, isAlert) {
      if (text) {
        this.infoModal = {
          isOpen: true,
          isAlert: isAlert ?? false,
          text: text,
          title: title
        };
      }
    },
    closeInfoModal() {
      this.infoModal = {
        isOpen: false,
        isAlert: false,
        text: '',
        title: ''
      };
    }
  }
};
</script>
